<template>
  <div class="testdetail public_main_bgc">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content" style="margin-top: 0; height: 48px; line-height: 48px">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>我的试题</el-breadcrumb-item>

            <el-breadcrumb-item>
              <span class="font_orange">试题详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="left_content">
        <el-card class="box-card">
          <!-- 内容-->
          <div class="main">
            <!-- 主体 循环部分-->
            <div class="contents">
              <div class="item1">
                <span>【题目】</span>
                <div v-html="detailData.title" class="rich_text_div_img"></div>
              </div>

              <div class="item3" v-if="detailData.qtpye == '选择题' && detailData.option_a != ''">
                <!-- 第一行 -->
                <span style="color: #00b5a3" class="one_span">【选项】</span>
                <!-- <p>
                  <span>A、{{ detailData.option_a }}</span>
                  <span>B、{{ detailData.option_b }}</span
                  ><br />
                  <span>C、{{ detailData.option_c }}</span>
                  <span>D、{{ detailData.option_d }}</span>
                  <br />
                  <span>E、{{ detailData.option_e }}</span>
                </p> -->
                <p>
                  <span>A、</span><span v-html="detailData.option_a" class="have_span_space rich_text_div_img"></span>
                  <span>B、</span><span v-html="detailData.option_b" class="rich_text_div_img"></span>
                  <br />
                  <span>C、</span><span v-html="detailData.option_c" class="have_span_space rich_text_div_img"></span>
                  <span>D、</span><span v-html="detailData.option_d" class="have_span_space rich_text_div_img"></span>
                  <br />
                  <span v-if="detailData.option_e && detailData.option_e != ''">E、</span><span v-html="detailData.option_e"
                    v-if="detailData.option_e && detailData.option_e != ''" class="rich_text_div_img"></span>
                </p>
              </div>
              <div class="item4">
                <p>
                  <span>更新时间：{{
                      detailData.update_time
                        ? detailData.update_time
                        : detailData.create_time
                    }}</span>
                  <span>年级：{{ detailData.gradeId }}</span>
                  <span>题型：{{ detailData.qtpye }}</span>
                  <span>难度：{{ detailData.diff }}</span>
                  <span>组卷次数：{{ detailData.use_time }}</span>
                </p>
              </div>
              <div class="item5">
                <!-- <el-button type="primary" plain>纠错</el-button> -->
                <el-popover width="230" trigger="click" style="margin: 0 10px" @after-leave="correctionData = ''" v-model="detailData.correctionShow">
                  <el-input type="textarea" :rows="3" placeholder="纠错原因..." v-model="correctionData">
                  </el-input>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="correctionErr(detailData)">提交</el-button>
                  </div>
                  <el-button type="primary" plain slot="reference">纠错</el-button>
                </el-popover>

                <el-button type="primary" plain @click="collection(detailData, 'one')">收藏</el-button>
              </div>
            </div>
            <!-- end -->
          </div>
          <div class="knowledge">
            <div class="knowledge_one">
              <span>【知识点】</span>
              <div class="knowledge_one_word">{{ detailData.knowledge }}</div>
            </div>
            <div class="knowledge_two">
              <span>【答案】</span>
              <div class="knowledge_two_word rich_text_div_img" v-html="detailData.answer"></div>
            </div>
            <div class="knowledge_two">
              <span>【解析】</span>
              <div class="knowledge_two_word rich_text_div_img" v-html="detailData.parse"></div>
            </div>
          </div>
          <div class="left_other">
            <p>类似试题</p>
            <!-- 内容-->
            <div class="main">
              <!-- 主体 循环部分-->
              <div class="contents" v-for="(item, index) in showsimilar" :key="index">
                <div class="item1">
                  <span>【题目】</span>
                  <div v-html="item.title" class="rich_text_div_img"></div>
                </div>

                <div class="item3" v-if="item.type == '选择题' && item.option_a != ''">
                  <!-- 第一行 -->
                  <span style="color: #00b5a3" class="one_span">【选项】</span>
                  <ul>
                    <li style="display: flex; align-items: center">
                      A:
                      <div v-html="item.option_a"></div>
                    </li>
                    <li>
                      B:
                      <div v-html="item.option_b" class="rich_text_div_img"></div>
                    </li>
                    <li>
                      C:
                      <div v-html="item.option_c" class="rich_text_div_img"></div>
                    </li>
                    <li>
                      D:
                      <div v-html="item.option_d" class="rich_text_div_img"></div>
                    </li>
                    <li>
                      E:
                      <div v-html="item.option_e" class="rich_text_div_img"></div>
                    </li>
                  </ul>
                  <!-- <p>
                    <span>{{  }}</span>
                    <span>{{ item.option_b }}</span><br />
                    <span>{{ item.option_c }}</span>
                    <span>{{ item.option_d }}</span>
                    <br />
                    <span>{{ item.option_e }}</span>
                  </p> -->
                </div>
                <div class="item4">
                  <p>
                    <span>更新时间：{{
                        item.update_time ? item.update_time : item.create_time
                      }}</span>
                    <span>年级：{{ item.grade }}</span>
                    <span>题型：{{ item.qtpye }}</span>
                    <span>难度：{{ item.diffcn }}</span>
                    <span>组卷次数：{{ item.is_set_paper }}</span>
                  </p>
                </div>
                <div class="item5">
                  <el-button type="primary" plain @click="intoview(item)">查看</el-button>
                  <el-popover width="230" trigger="click" style="margin: 0 10px" @after-leave="correctionData = ''" v-model="item.correctionShow">
                    <el-input type="textarea" :rows="3" placeholder="纠错原因..." v-model="correctionData">
                    </el-input>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="correctionErr(item)">提交</el-button>
                    </div>
                    <el-button type="primary" plain slot="reference">纠错</el-button>
                  </el-popover>

                  <el-button type="primary" plain @click="collection(item, 'two')">收藏</el-button>
                </div>
              </div>
              <!-- end -->
            </div>
          </div>
        </el-card>
      </div>
      <div class="right_area">
        <el-card class="right_content">
          <p>相关试卷</p>
          <div class="right_word">
            <span>暂无数据</span>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "testdetail",
  data() {
    return {
      // 试卷id
      question_id: "",
      // 详情数据
      detailData: {},
      // 相似数据
      similardata: [],
      // 展示的相似数据
      showsimilar: [],
      // 纠错数据
      correctionData: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化函数
    init() {
      this.question_id = this.$route.query.question_id;
      this.getdetail();
    },
    // 详情接口
    getdetail() {
      let obj = {
        params: {
          question_id: this.question_id,
        },
      };
      this.$axios.get("question/get_info", obj).then((res) => {
        this.detailData = res.data.data;
        let {
          query_msg,
          pharse,
          subject_id,
          diff,
          qtpye: type,
        } = this.detailData;
        let params2 = { query_msg, pharse, subject_id, diff, type };
        let obj2 = {
          params: params2,
        };
        //  let obj3={
        //    params:{
        //      query_msg:"20561!@@!20590",
        //      pharse:1,
        //      subject_id:2,
        //      diff:3,
        //      type:"作图题"
        //    }
        //  }
        this.$axios.get("question/get_similar_list", obj2).then((res2) => {
          let all = res2.data.data.data;
          for (var item in all) {
            // console.log("eee",all[item])
            var arr = all[item];
          }
          if (arr) {
            let findata = arr[0].details;
            this.similardata = findata;
            if (this.similardata) {
              this.showsimilar.push(this.similardata[0]);
              this.showsimilar.push(this.similardata[1]);
            }
          }

          // console.log("eee",findata)
        });
      });
    },
    // 提交纠错原因
    correctionErr(item) {
      let obj = {
        id: item.id,
        msg: this.correctionData,
      };
      this.$axios.post("question/do_error", obj).then((res) => {
        this.$message.success("提交成功");
        // this.correctionData="";
        item.correctionShow = false;
      });
    },
    // 取消收藏函数
    collection(item, str) {
      if (str == "two") {
        var obj = {
          question_id: item.id,
        };
      } else {
        var obj = {
          question_id: item.self,
        };
      }

      this.$axios.post("question/collection_cancel", obj).then((res) => {
        this.$message.success(res.data.data.data);
        // this.myCollection();
      });
    },
    // 跳转到查看页面
    intoview(item) {
      this.$router.push({
        name: "testdetail",
        query: {
          question_id: item.question_id,
          t: Date.now(),
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.testdetail {
  display: flex;
  justify-content: center;
  .center {
    margin-bottom: 60px;
    .left_content {
      float: left;
      width: 1060px;
      //   border: 1px solid black;
      .box-card {
        .knowledge {
          border: 1px solid #ebebeb;
          border-radius: 6px;
          padding: 20px 20px;
          margin-bottom: 40px;
          margin-top: 20px;
          .knowledge_one {
            display: flex;
            font-size: 16px;
            span:nth-child(1) {
              color: #3489fe;
            }
            border-bottom: 1px dashed#ebebeb;
            padding-bottom: 20px;
            margin-bottom: 20px;
          }
          .knowledge_two {
            display: flex;
            font-size: 16px;
            span:nth-child(1) {
              color: #3489fe;
              flex-shrink: 0;
            }
            &:nth-child(2) {
              margin-bottom: 30px;
            }
          }
          &:hover {
            border: 1px solid #3489fe;
            transition: border 0.5s;
          }
        }
        .left_other {
          border: 1px solid #ebebeb;
          border-radius: 6px;
          padding: 20px 20px;
          p {
            border-left: 3px solid #3489fe;
            padding-left: 20px;
            font-weight: 600;
            color: #3489fe;
            margin-bottom: 20px;
          }
        }
      }
    }
    .right_area {
      float: right;
      width: 300px;

      .right_content {
        height: 400px;
        p {
          border-left: 3px solid #3489fe;
          padding-left: 20px;
          font-weight: 600;
          color: #3489fe;
          margin-bottom: 20px;
        }
        .right_word {
          margin-left: 50px;
        }
      }
    }
  }
}
//主要内容部分
.main {
  display: flex;
  flex-wrap: wrap;
  // width: 1045px;
  width: 100%;
  .contents {
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    border-radius: 6px;
    padding: 20px 20px;
    width: 100%;

    .item1 {
      span {
        color: #3489fe;
      }
      font-size: 16px;

      display: flex;
      margin-bottom: 60px;
    }
    .item2 {
      width: 213px;
      height: 112px;
      border: 1px solid skyblue;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .item3 {
      display: flex;
      margin-bottom: 20px;
      .one_span {
        margin-right: 10px;
        flex-shrink: 0;
      }
      p {
        span {
          // margin-right: 40px;
          line-height: 30px;
        }
        .have_span_space {
          margin-right: 40px;
        }
      }

      ul {
        li {
          width: 700px;
        }
      }
    }
    .item4 {
      width: 100%;

      height: 42px;
      background-color: #eaf3ff;
      margin-bottom: 20px;
      p {
        line-height: 42px;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        span {
          margin-right: 80px;
        }
      }
    }
    .item5 {
      display: flex;
      justify-content: flex-end;
      // padding-left: 590px;
    }
    &:hover {
      border: 1px solid#3489fe;
    }
    transition: border 0.5s;
  }
}
.block {
  display: flex;
  justify-content: center;
}
.have_flex {
  display: flex;
}
</style>
